import React from 'react';
import styled from '@emotion/styled';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { palette } from '@theme';

const Wrap = styled.div`
  position: absolute;
  z-index: 20;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  display: flex;
  flex-direction: column;

  > * {
    margin: 5px;
  }

  @media (max-width: 1300px) , screen and (max-height: 700px)  {
    right: 10px;
    background: rgba(255, 255, 255, 0.4);

    > * {
      margin: 0px;
      padding: 6px;
    }
  }
`;

const Pagination = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Bullet = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ccc;
  margin: 5px;

  &.active {
    background: ${palette.primary.main};
  }
`;

export default ({ pages, swiper, activePage }) => (
  <Wrap>
    <IconButton
      color="primary"
      aria-label="Previos page"
      onClick={() => swiper.slidePrev()}
    >
      <KeyboardArrowUpIcon />
    </IconButton>
    <Pagination>
      {pages.map((_, i) => <Bullet key={i} className={activePage === i && 'active'} />)}
    </Pagination>
    <IconButton
      color="primary"
      aria-label="Next page"
      onClick={() => swiper.slideNext()}
    >
      <KeyboardArrowDownIcon />
    </IconButton>
  </Wrap>
);
