import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { darken } from 'polished';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { useWindowSize } from 'react-use';
import min from 'lodash/min';

import HexOverlay from './HexOverlay';

/* http://brenna.github.io/csshexagon/ */

const Wrap = styled.div`
  position: relative;
  width: ${p => p.width}px;
  height: ${p => p.height.toFixed(2)}px;
  margin: ${p => (p.height / 2).toFixed(2)}px 0;
  background-image: url(${p => p.backgroundImage});
  background-size: auto ${p => (p.height * 2 - p.borderOffset * 4).toFixed(4)}px;
  background-position: center;
  border-left: solid ${p => p.borderWidth}px ${p => p.borderColor};
  border-right: solid ${p => p.borderWidth}px ${p => p.borderColor};
  transition: opacity 400ms ease-in-out, transform 400ms ease-in-out;
  box-shadow: 0px 0px 12px rgba(150, 150, 150, 0.2);
  background-color: #eaeaea;

  @media (max-width: 1300px) {
    cursor: pointer;
  }

  .hexTop,
  .hexBottom {
    position: absolute;
    z-index: 1;
    width: ${p => p.capWidth.toFixed(2)}px;
    height: ${p => p.capWidth.toFixed(2)}px;
    overflow: hidden;
    transform: scaleY(${p => p.scaleFactor.toFixed(4)}) rotate(-45deg);
    background: inherit;
    left: ${p => ((p.width - p.capWidth) / 2 - p.borderWidth).toFixed(2)}px;
    box-shadow: 0px 0px 12px rgba(150, 150, 150, 0.2);
  }

  /*counter transform the bg image on the caps*/
  .hexTop:after,
  .hexBottom:after {
    content: "";
    position: absolute;
    width: ${p => p.width - (p.borderWidth * 2)}px;
    height: ${p => p.height - (p.borderWidth * p.scaleFactor * 2)}px;
    transform: rotate(45deg) scaleY(${p => 1 / p.scaleFactor}) translateY(${p => (-p.height / 2 + p.borderOffset).toFixed(4)}px);
    transform-origin: 0 0;
    background: inherit;
  }

  .hexTop {
    top: -${p => p.offset}px;
    border-top: solid ${p => p.borderWidth * Math.sqrt(2)}px ${p => p.borderColor};
    border-right: solid ${p => p.borderWidth * Math.sqrt(2)}px ${p => p.borderColor};
  }

  .hexTop:after {
    background-position: center top;
  }

  .hexBottom {
    bottom: -${p => p.offset}px;
    border-bottom: solid ${p => p.borderWidth * Math.sqrt(2)}px ${p => p.borderColor};
    border-left: solid ${p => p.borderWidth * Math.sqrt(2)}px ${p => p.borderColor};
  }

  .hexBottom:after {
    background-position: center bottom;
  }

  &::after {
    content: "";
    position: absolute;
    top: ${p => p.borderWidth * p.scaleFactor}px;
    left: 0;
    width: ${p => p.width - p.borderWidth * 2}px;
    height: ${p => p.height - p.borderWidth * p.scaleFactor * 2}px;
    z-index: 2;
    background: inherit;
  }
`;

const SlotWrap = styled.div`
  width: ${p => p.width * 0.56}px;
  position: absolute;
  z-index: 4;
  text-align: center;
  display: flex;
  justify-content: center;

  &:after {
    display: ${p => !p.triangle && 'none'};
    position: absolute;
    content: '';
    z-index: 4;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: ${p => p.width / 5}px 0 ${p => p.width / 5}px ${p => p.width / 15}px;
    border-color: transparent transparent transparent #fff;
    transform: rotateZ(90deg) translateX(4px);
    top: -${p => p.width / 5 - p.width / 30}px;
  }
`;

const Content = styled.div`
  z-index: 4;
  position: absolute;
  top: -${p => p.height / 10}px;
  width: 100%;
  bottom: -${p => p.height / 10}px;
  display: flex;
  flex-direction: column;
  justify-content: ${p => p.contentTopAlign};
  align-items: center;
  padding-left: ${p => p.width / 10}px;
  padding-right: ${p => p.width / 10}px;
  text-align: center;
`;

const TopLeft = styled(SlotWrap)`
  transform: rotateZ(-30deg) translateY(3px);
  transform-origin: top left;
  top: 0%;
  left: 0%;
`;

const TopRight = styled(SlotWrap)`
  transform: rotateZ(30deg) translateY(3px);
  transform-origin: top right;
  top: 0%;
  right: 0%;
`;

const Left = styled(SlotWrap)`
  top: 0%;
  left: 0%;
  transform-origin: top left;
  transform: rotateZ(-90deg) translateY(3px) translateX(-100%);
`;

const Right = styled(SlotWrap)`
  top: 0%;
  right: 0%;
  transform-origin: top right;
  transform: rotateZ(90deg) translateY(3px) translateX(100%);
`;


const BottomLeft = styled(SlotWrap)`
  transform: rotateZ(30deg) translateY(-3px);
  transform-origin: bottom left;
  bottom: 0%;
  left: 0%;

  &:after {
    transform: scaleX(-1) rotateZ(-90deg);
    bottom: -${p => p.width / 5 - p.width / 24}px;
    top: auto;
  }
`;

const BottomRight = styled(SlotWrap)`
  transform: rotateZ(-30deg) translateY(-3px);
  transform-origin: bottom right;
  bottom: 0%;
  right: 0%;

  &:after {
    transform: scaleX(-1) rotateZ(-90deg);
    bottom: -${p => p.width / 5 - p.width / 24}px;
    top: auto;
  }
`;

const Icon = styled.img`
  z-index: 3;
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 50%;
  max-width: 100%;
  height: auto;
`;

const ContentInner = styled.div`
  width: 100%;
  word-break: break-word;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    img {
      max-height: 60px;
      width: auto !important;
    }
  }
`;

const ForDesktop = styled(ContentInner)`
  @media (max-width: 1300px) , screen and (max-height: 700px)  {
    display: ${p => (!p.showAlwaysDesktopView && 'none')};
  }
`;

const ForMobile = styled(ContentInner)`
  display: none;
  @media (max-width: 1300px) , screen and (max-height: 700px)  {
    display: ${p => (p.showAlwaysDesktopView ? 'none' : 'block')};
  }
`;

const MobileControl = styled.button`
  border: none;
  background: transparent;
  position: absolute;
  display: none;
  bottom: 0px;
  z-index: 6;
  margin-bottom: ${p => p.width / 20}px;
  outline: none;
  bottom: -${p => p.offset}px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${p => p.color};
  cursor: pointer;

  @media (max-width: 1300px) , screen and (max-height: 700px)  {
    display: block;

    svg {
      font-size: ${p => p.width / 10}px;
    }
  }
`;

const Hex = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  const hasIcon = props.icon;
  const {
    shortText,
    contentTopAlign = hasIcon ? 'flex-start' : 'center',
    clickable = true,
    showAlwaysDesktopView,
    open,
    onClick,
    heading,
    hexStyle,
    body,
    topLeftSlot,
    topRightSlot,
    leftSlot,
    rightSlot,
    bottomLeftSlot,
    bottomRightSlot,
    borderWidth = 2,
    width,
    icon,
    triangles,
    iconStyle,
    ...rest
  } = props;

  const backgroundColor = hexStyle?.backgroundColor ? hexStyle.backgroundColor : '#eaeaea';

  const css = {
    ...rest,
    backgroundImage: hexStyle.backgroundImage,
    borderWidth,
    borderColor: darken(0.1, backgroundColor),
    width,
    height: width / Math.sqrt(3),
    offset: width / Math.sqrt(2) / 2,
    capWidth: width / Math.sqrt(2),
    // eslint-disable-next-line no-mixed-operators
    scaleFactor: Math.tan(30 * Math.PI / 180),
    borderOffset: borderWidth / Math.sqrt(3),
    triangleHeight: width / 15,
  };

  return (
    <Wrap {...css} style={hexStyle} onClick={onClick}>
      <div className="hexTop" />
      <div className="hexBottom" />
      <Content {...css} contentTopAlign={contentTopAlign}>
        {heading && <ForDesktop showAlwaysDesktopView={showAlwaysDesktopView}>{heading}</ForDesktop>}
        {body && <ForDesktop showAlwaysDesktopView={showAlwaysDesktopView}>{body}</ForDesktop>}
        {shortText && <ForMobile showAlwaysDesktopView={showAlwaysDesktopView}>{shortText}</ForMobile>}
      </Content>
      <TopLeft triangle={triangles?.includes('topLeft')} {...css}>{topLeftSlot}</TopLeft>
      <TopRight triangle={triangles?.includes('topRight')} {...css}>{topRightSlot}</TopRight>
      <Left triangle={triangles?.includes('left')} {...css}>{leftSlot}</Left>
      <Right triangle={triangles?.includes('right')} {...css}>{rightSlot}</Right>
      <BottomLeft triangle={triangles?.includes('bottomLeft')} {...css}>{bottomLeftSlot}</BottomLeft>
      <BottomRight triangle={triangles?.includes('bottomRight')} {...css}>{bottomRightSlot}</BottomRight>
      {icon && <Icon style={iconStyle} src={icon} alt="icon" />}
      {clickable && (
        <MobileControl {...css} {...hexStyle}>
          {open ? <RemoveCircleIcon /> : <AddCircleIcon />}
        </MobileControl>
      )}
    </Wrap>
  );
};

export default (props) => {
  const [open, setOpen] = useState(false);
  const [hexFullWidth, setHexFullWidth] = useState(0);
  const { width, clickable = true, ...rest } = props;
  const { width: windowWidth, height: windowHeight } = useWindowSize();

  const onMobile = () => window.matchMedia('(max-width: 1300px) , screen and (max-height: 700px)').matches;
  const handleOpen = (value) => onMobile() && clickable && setOpen(value);

  useEffect(() => {
    if (!onMobile()) {
      setOpen(false);
      return;
    }

    setHexFullWidth(min([windowWidth, windowHeight]) - 20);
  }, [windowWidth, windowHeight]);

  return (
    <>
      <HexOverlay open={open} onClose={() => setOpen(false)}>
        <Hex
          showAlwaysDesktopView
          open={open}
          {...rest}
          width={hexFullWidth}
          onClick={() => handleOpen(false)}
          clickable="true"
        />
      </HexOverlay>
      <Hex {...props} onClick={() => handleOpen(true)} open={open} />
    </>
  );
};
