import React from 'react';
import styled from '@emotion/styled';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const LanguagePicker = styled(FormControl)`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;
`;

export default () => {
  const { i18n, t } = useTranslation();
  return (
    <LanguagePicker>
      <InputLabel id="language-picker">{t('language')}</InputLabel>
      <Select
        labelId="language-picker"
        value={i18n.language}
        onChange={e => i18n.changeLanguage(e.target.value)}
      >
        <MenuItem value="de">Deutsch</MenuItem>
        <MenuItem value="en">English</MenuItem>
      </Select>
    </LanguagePicker>
  );
};
