import { css } from '@emotion/core';
import { palette, typography } from '@theme';

const { fontSize, fontFamily } = typography;

export default css`
  *, *:before, *:after {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }

  html, body {
    margin: 0px;
    padding: 0px;
    height: 100%;
    outline: none;
  }

  body {
    font-family: ${fontFamily};
    overflow-y: auto;
    font-size: ${fontSize}px;
    line-height: ${fontSize * 1.5}px;
    color: #333;
    background-color: #f7f7f7;
    background-image: url(${require('@assets/img/intro-back.jpg').default});
    background-size: cover;
    background-position: bottom;
  }

  #app {
    height: 100%;
  }

  @media (max-width: 600px) {
    body {
      font-size: 0.9em;
    }
  }

  a, a:hover, a:focus  {
    outline: none;
    color: ${palette.primary.main};
  }

  ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
  }

  h1, h2, h3, h4 {
    font-weight: 500;
  }

  .swiper-container, .swiper-wrapper, .swiper-slide {
    height: 100%;
  }

  .swiper-slide {
    overflow: hidden;
  }

  .hex-view {
    font-size: 14px;
    line-height: 1.5em;

    h1, h3, h3, p {
      margin: 8px 0;
    }
  }

  @media (max-width: 1300px) , screen and (max-height: 700px)  {
    .hex-view {
      font-size: 10px;
      line-height: 1.2em;
    }

    h1, h3, h3, p {
      margin: 6px 0;
    }
  }
`;
