import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import cloneDeep from 'lodash/cloneDeep';
import flatten from 'lodash/flatten';
import orderBy from 'lodash/orderBy';
import { useTranslation } from 'react-i18next';

import HexGrid from '@components/HexGrid';
import logo from '@assets/img/Aqua_Resource_Logo_final.png';

import { palette } from '@theme';

const Wrap = styled.div`
  width: 100%;
  height: 100%;

  @media (max-width: 1300px) , screen and (max-height: 700px)  {
    .hex-view {
      margin: 0 50px;
    }
  }
`;

let timeouts = [];
const clearTimeouts = () => {
  timeouts.forEach(t => clearTimeout(t));
  timeouts = [];
};

export default ({ active }) => {
  const { t } = useTranslation('circleConsulting');
  const getDefaultGridItems = () => ([
    [{
      hexStyle: {
        color: '#fff', // White
        backgroundColor: '#0073a0', // 2nd darkest blue RGB: 0/115/160
        opacity: 0,
        transform: 'translate(30px, 30px)',
      },
      animateAt: 0,
      heading: <h3>{ t('machineLearning.heading') }</h3>,
      shortText: <h3>{ t('machineLearning.shortText') }</h3>,
      body: (
        <p>{ t('machineLearning.body') }</p>
      ),
      triangles: ['left'],
    }, {
      animateAt: 1,
      hexStyle: {
        color: '#fff',
        backgroundColor: '#1ea1c1', // Middle blue RGB: 30/161/193
        opacity: 0,
        transform: 'translate(-30px, 30px)',
      },
      heading: <h3>{ t('historyConsulting.heading') }</h3>,
      shortText: <h3>{ t('historyConsulting.shortText') }</h3>,
      body: <p>{ t('historyConsulting.body') }</p>,
      triangles: ['left'],
    }],
    [{
      animateAt: 5,
      hexStyle: {
        color: '#fff',
        backgroundColor: '#00587f', // Darkest blue RGB: 0/88/127
        opacity: 0,
        transform: 'translate(30px, 0px)',
      },
      heading: <h3>{ t('winWin.heading') }</h3>,
      shortText: <h3>{ t('winWin.shortText') }</h3>,
      triangles: ['bottomRight'],
      body: (
        <p>
          { t('winWin.body') }
          <a target="_blank" href="mailto:consulting@aquaresource.eu" rel="noreferrer" style={{ backgroundColor: 'white' }}>
            consulting@aquaresource.eu
          </a>
        </p>
      ),
    }, {
      animateAt: null,
      hexStyle: { color: palette.primary.main, backgroundColor: '#fff' },
      heading: (
        <div>
          <img style={{ width: '100%' }} src={logo} alt="logo" /> <br />
          { t('center.shortText') }
        </div>
      ),
      shortText: (
        <div>
          <img style={{ width: '100%' }} src={logo} alt="logo" /> <br />
          { t('center.shortText') }
        </div>
      ),
      body: (
        <p>
          { t('center.body') }
        </p>
      ),
    }, {
      animateAt: 2,
      hexStyle: {
        color: '#fff',
        backgroundColor: '#65c3dc', // 2nd lightest blue RGB: 152/212/228
        opacity: 0,
        transform: 'translate(-30px, 0px)',
      },
      heading: <h3>{ t('digitalSovereignty.heading') }</h3>,
      shortText: <h3>{ t('digitalSovereignty.shortText') }</h3>,
      body: (
        <p>{ t('digitalSovereignty.body') }</p>
      ),
      triangles: ['topLeft'],
    }],
    [{
      animateAt: 4,
      hexStyle: {
        color: '#00587f', // Darkest blue RGB: 0/88/127
        backgroundColor: '#d2d4d7', // Light grey RGB: 210/212/215
        opacity: 0,
        transform: 'translate(30px, -30px)',
      },
      heading: <h3>{ t('agile.heading') }</h3>,
      shortText: <h3>{ t('agile.shortText') }</h3>,
      triangles: ['right'],
      body: (
        <p>{ t('agile.body') }
        </p>
      ),
    }, {
      animateAt: 3,
      hexStyle: {
        color: '#00587f', // Darkest blue RGB: 0/88/127
        backgroundColor: '#98d4e4', // Lightest blue RGB: 152/212/228
        opacity: 0,
        transform: 'translate(-30px, -30px)',
      },
      heading: <h3>{ t('sectors.heading') }</h3>,
      shortText: <h3>{ t('sectors.shortText') }</h3>,
      triangles: ['topRight'],
      body: (
        <p>{ t('sectors.body') }</p>
      ),
    }],
  ]);

  const [gridItems, setGridItems] = useState(getDefaultGridItems);

  useEffect(() => {
    setGridItems(getDefaultGridItems);
    clearTimeouts();
    if (!active) return;

    timeouts.push(setTimeout(() => {
      // remember old indexes!
      const memorized = cloneDeep(gridItems.map((row, _rowIndex) => {
        return row
          .map((i, _hexIndex) => ({ ...i, _rowIndex, _hexIndex }))
          .filter(i => i.animateAt !== null);
      }));

      // order it by animateAt
      const animationOrder = orderBy(flatten(memorized), 'animateAt');

      animationOrder.forEach((hex, index) => {
        timeouts.push(setTimeout(() => {
          setGridItems((grid) => {
            const gridCopy = cloneDeep(grid);
            const copy = cloneDeep(hex);
            copy.hexStyle.opacity = 1;
            copy.hexStyle.transform = 'translate(0px, 0px)';
            gridCopy[hex._rowIndex][hex._hexIndex] = copy;
            return gridCopy;
          });
        }, index * 300));
      });
    }, 300));
  }, [active]);

  return (
    <Wrap>
      <HexGrid grid={gridItems} className="hex-view" />
    </Wrap>
  );
};
