import React from 'react';
import styled from '@emotion/styled';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const CustomModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;

  .body {
    width: 100%;
    position: relative;
    outline: none;
    display: flex;
    justify-content: center;
  }
`;

export default ({ open, onClose, children }) => {
  return (
    <CustomModal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
        <div className="body" onClick={onClose}>
          {children}
        </div>
      </Fade>
    </CustomModal>
  );
};

