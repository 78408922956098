import React from 'react';
import { useTranslation } from 'react-i18next';


export default () => {
  const { t } = useTranslation('contact');
  return (
    <>
      <h1>{ t('imprint') }</h1>
      <div>AquaResource GmbH</div>
      <div>Bingener Str. 39</div>
      <div>D-80993 München</div>
      <div>{t('managingDirectors') }: Andreas C. Holzhammer</div>
      <div>{ t('registration') } HRB 288 023</div>
    </>
  );
};
