import 'core-js';
import 'regenerator-runtime/runtime';

import React, { useEffect, useState, useRef } from 'react';
import { render } from 'react-dom';
// import { Route, Switch, Redirect } from 'react-router-dom';
// import { Router } from 'react-router';
import { ThemeProvider, StylesProvider } from '@material-ui/styles';
import styled from '@emotion/styled';
import { Global } from '@emotion/core';
import './i18n';
// we dont use the react version, because of ie11 support... that's why we use version 4 btw
import Swiper from 'swiper/bundle';
import 'swiper/css';

// import history from '@history';
import theme from '@theme';
import styles from './styles';

import Control from './components/Control';
import MovingLogo from './components/MovingLogo';
import NewsLogo from './components/NewsLogo';
import ConsultingLogo from './components/ConsultingLogo';
import LanguagePicker from './components/LanguagePicker';

import Intro from './pages/Intro';
import Circle from './pages/Circle';
import UseCases from './pages/UseCases';
import Contact from './pages/contact';
import Consulting from './pages/Consulting';

const Main = styled.main`
  display: block;
  height: 100%;
  position: relative;
`;

const pages = [{
  hash: 'intro',
  component: active => <Intro active={active} />,
}, {
  hash: 'circle',
  component: active => <Circle active={active} />,
}, {
  hash: 'use-cases',
  component: active => <UseCases active={active} />,
}, {
  hash: 'contact',
  component: active => <Contact active={active} />,
}, {
  hash: 'consulting',
  component: active => <Consulting active={active} />,
}];

const App = () => {
  const [activePage, setActivePage] = useState(null);
  const [sw, setSw] = useState(null);
  const swiperContainer = useRef(null);

  useEffect(() => {
    const speed = window.matchMedia('(max-width: 1300px) , screen and (max-height: 700px)').matches ? 300 : 600;
    const swiper = new Swiper(swiperContainer.current, {
      init: false,
      speed,
      direction: 'vertical',
      parallax: true,
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
      hashNavigation: { watchState: true },
      mousewheel: { forceToAxis: true },
      a11y: 'true',
    });

    swiper.on('init', () => setActivePage(swiper.activeIndex));
    swiper.on('slideChange', () => setActivePage(swiper.activeIndex));
    swiper.init();
    setSw(swiper);
  }, []);

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Global styles={styles} />
        <Main>
          <MovingLogo swiper={sw} />
          <LanguagePicker />
          <NewsLogo swiper={sw} />
          <ConsultingLogo swiper={sw} />
          <div ref={swiperContainer} className="swiper-container">
            <div className="swiper-wrapper">
              {pages.map((p, i) => (
                <div key={p.hash} className="swiper-slide" data-hash={p.hash}>
                  {p.component(i === activePage)}
                </div>
              ))}
            </div>
          </div>
        </Main>
        <Control pages={pages} swiper={sw} activePage={activePage} />
      </ThemeProvider>
    </StylesProvider>
  );
};

render(<App />, document.getElementById('app'));
