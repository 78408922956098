import React, { useState, useEffect } from 'react';
import Fade from '@material-ui/core/Fade';
import styled from '@emotion/styled';

const Wrap = styled.div`
  position: absolute;
  z-index: 20;
  top: 20px;
  left: 20px;
  width: 80px;
  opacity: 0.8;
  pointer-events: none;

  @media (orientation: landscape) and (max-width: 700px) , (orientation: portrait) and (max-height: 700px)  {
    display: none;
  }

  img {
    width: 100%;
    height: auto;
  }
`;

export default ({ swiper }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!swiper) return;
    if (swiper.activeIndex > 0) setVisible(true);
    swiper.on('slideChangeTransitionStart', () => swiper.activeIndex === 0 && setVisible(false));
    swiper.on('slideChangeTransitionEnd', () => swiper.activeIndex > 0 && setVisible(true));
  }, [swiper]);

  return (
    <Wrap>
      <Fade timeout={600} in={visible}>
        <img alt="logo" src={require('@assets/img/Aqua_Resource_Logo_final.png').default} />
      </Fade>
    </Wrap>
  );
};
